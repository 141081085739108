import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Stack } from '@mui/material'
import { pwaStatusBarHeight } from 'components/NestMuiTheme'
import PageImage, {
  PageImageData,
} from 'components/StoryPlayer/pages/PageImage'
import PageVideo, {
  PageVideoData,
} from 'components/StoryPlayer/pages/PageVideo'
import PageForm, {
  PageFormData,
  PageFormProps,
} from 'components/StoryPlayer/pages/PageForm'
import PageCustom, {
  PageCustomData,
} from 'components/StoryPlayer/pages/PageCustom'
import PageStoryStart, {
  PageStoryStartData,
} from 'components/StoryPlayer/pages/PageStoryStart'
import PageStoryComplete, {
  PageStoryCompleteData,
} from 'components/StoryPlayer/pages/PageStoryComplete'
import PageTemplateOverview, {
  PageTemplateOverviewData,
} from 'components/StoryPlayer/pages/PageTemplateOverview'
import { getCurrentTime } from '@shared/utils/DateUtil'
import {
  StoryAdditionalRule,
  StoryConnectionRule,
  StorySxProps,
} from 'components/StoryPlayer'
import PageBackground from 'components/StoryPlayer/PageBackground'
import * as textures from 'assets/images/textures'
import { DynamicBackgroundProps } from 'components/DynamicBackground'
import ContentDrawer from 'components/ContentDrawer'
import useDialog from 'hooks/useDialog'
import useArticleQuery from 'hooks/useArticleQuery'
import LoadingBackdrop from 'components/LoadingBackdrop'
import { isArticleUrl } from 'components/blocks/TextBlock'

const getDistance = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
) => {
  const a = x1 - x2
  const b = y1 - y2
  return Math.sqrt(a * a + b * b)
}

// Implemented for specific page types:
// custom, form, story-start, story-complete
export type BasePageProps = {
  onNext: () => Promise<void>
  submitLabel?: StoryPageData['submitLabel']
  submitLabelOnEmpty?: StoryPageData['submitLabelOnEmpty']
  enableClose?: StoryPageData['enableClose']
  disableMotion?: boolean
  enableActionIconButton?: boolean
  sx?: StorySxProps
}

export type PageTheme = {
  variant?: DynamicBackgroundProps['variant']
  texture?: keyof typeof textures
  textureColor?: string
  background?: string
  backgroundColor?: string
  color?: string
  secondaryColor?: string
  controlsDarkMode?: boolean
}

export interface PageData {
  type:
    | 'image'
    | 'video'
    | 'form'
    | 'custom'
    | 'story-start'
    | 'story-complete'
    | 'template-overview'
  id: string
  theme?: PageTheme
  audioSrc?: string
  audioTranscript?: string
  className?: string
  sx?: StorySxProps
  hiddenControls?: boolean
  enableClose?: boolean
  submitLabel?: string
  submitLabelOnEmpty?: string
  enableActionIconButton?: boolean
  disableBack?: boolean
  disableProgress?: boolean
  disableScrollHint?: boolean
  overrideHeaderTitle?: string
  defaultConnectionPageId?: string
  connectionRules?: StoryConnectionRule[]
  additionalRules?: StoryAdditionalRule[]
  disableMotion?: boolean
  // Template pages are defined by `templateDataKey`.
  // The `templateDataKey` should reference a key questionnaire responses data with an array value.
  // Pages of the same `templateDataKey` should share their own connection graph.
  // They are excluded from the story player, but are referenced when
  // story navigation resolves a connection to one of them.
  // At this event, a set of the template pages matching the `templateDataKey`
  // will be dynamically injected into the active story and allowing them
  // to be navigable through the story's connection graph.
  templateDataKey?: string
}

export type StoryPageData =
  | PageImageData
  | PageVideoData
  | PageFormData
  | PageCustomData
  | PageStoryStartData
  | PageStoryCompleteData
  | PageTemplateOverviewData

export type StoryPageProps = StoryPageData & {
  onNext?: (currentPage: StoryPageProps) => Promise<void>
  onBack?: (currentPage: StoryPageProps) => void
  onHideControls?: (hidden: boolean) => void
  onClose?: () => void
  onSubmit?: PageFormProps['onSubmit']
  setHiddenTitle?: (value: boolean) => void
  gotoPage: (pageId: string) => void
  loading?: boolean
}

export default function StoryPage(
  props: StoryPageProps,
): JSX.Element {
  const {
    hiddenControls,
    onHideControls,
    disableMotion,
    type,
    setHiddenTitle,
    enableActionIconButton,
    theme = {},
    loading,
  } = props
  const scrollContainer = useRef<HTMLDivElement>(null)
  const [articleDialogProps, { setOpen: setArticleDialogOpen }] =
    useDialog('article')
  const [
    article,
    {
      fetch: fetchArticle,
      loading: articleLoading,
      error: articleError,
    },
  ] = useArticleQuery()
  const [paused, setPaused] = useState(false)
  const pointerDownTime = useRef(-1)
  const pointerMoved = useRef(false)
  const pointerDownPosition = useRef({ x: 0, y: 0 })
  const onNext = useCallback(() => props.onNext?.(props), [props])
  const onBack = useCallback(() => props.onBack?.(props), [props])
  const handleFormSubmit = useCallback(
    async (data, fields) => {
      await props.onSubmit(data, fields)
      props.onNext?.(props)
    },
    [props],
  )
  const handleFormSkip = useCallback(
    () => props.onNext?.(props),
    [props],
  )

  // Scroll to top when page changes.
  useLayoutEffect(() => {
    scrollContainer.current?.scrollTo({ top: 0 })
  }, [props.id])

  useEffect(() => {
    if (onHideControls) {
      if (paused && !pointerMoved.current) {
        onHideControls(true)
      } else {
        onHideControls(false)
      }
    }
  }, [paused, onHideControls])

  return (
    <>
      <PageBackground
        id={props.id}
        theme={theme}
        disableMotion={disableMotion}
      />

      <Stack
        ref={scrollContainer}
        className={props.className}
        sx={{
          '& .textGradient': {
            '& .MuiBox-root': {
              // Hack to fix animation.
              opacity: '1 !important',
              transform: 'none !important',
            },
            background: `linear-gradient(45deg, #E75732, #FF9C1A 80%)`,
            fontWeight: 800,
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            WebkitBackgroundClip: 'text',
            WebkitLinearGradient: `(45deg, #E75732, #FF9C1A 80%)`,
            WebkitTextFillColor: 'transparent',
            transform: 'translate3d(0, 0, 0)',
          },
          '& a:not(.MuiButton-root)': {
            '&:hover': {
              color: 'primary.main',
            },
            color: 'primary.light',
          },
          '& iframe': {
            height: 1,
            width: 1,
          },
          '@media (hover: none) and (pointer: coarse)': {
            userSelect: 'none',
          },
          '@media all and (display-mode: standalone)': {
            top: pwaStatusBarHeight,
          },
          borderRadius: 2,
          bottom: 0,
          justifyContent: 'flex-start',
          left: 0,
          overflowX: 'hidden',
          overflowY: 'auto',
          overscrollBehavior: 'contain',
          position: 'absolute',
          right: 0,
          scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
          top: 0,
          zIndex: 0,
          ...props.sx,
        }}
        onPointerDown={(e) => {
          pointerDownPosition.current.x = e.pageX
          pointerDownPosition.current.y = e.pageY
          pointerMoved.current = false
          const target = e.target as HTMLElement
          const isTargetActionable = !!target.closest(
            'button, a, input, [role=button], [role=option]',
          )

          // Only pause when pressed on elements other than button and links.
          if (!isTargetActionable) {
            pointerDownTime.current = getCurrentTime()
            if (props.type === 'video' && props.duration) {
              setPaused(true)
            }
          }
        }}
        onPointerUp={() => {
          setPaused(false)
        }}
        onPointerMove={({ pageX, pageY }) => {
          const { x, y } = pointerDownPosition.current
          const dist = getDistance(pageX, pageY, x, y)

          // Show controls if pointer moved past a certain range.
          if (dist > 8) {
            onHideControls?.(false)
            pointerMoved.current = true
          }
        }}
        onTouchEnd={() => {
          setPaused(false)
        }}
        onContextMenu={(e) => e.preventDefault()}
        onClickCapture={async (e) => {
          const target = e.target as HTMLAnchorElement
          let element: HTMLAnchorElement = target
          let href = element?.getAttribute('href')

          // Find the closest parent anchor element with href.
          // Needed when the click target is nested in an anchor element.
          if (element) {
            while (!href && element) {
              element = element?.parentElement as HTMLAnchorElement
              href = element?.getAttribute('href')
            }
          }

          if (isArticleUrl(href)) {
            e.preventDefault()
            e.stopPropagation()
            await fetchArticle(element.href)
            setArticleDialogOpen(true)
          }
        }}
      >
        {type === 'image' && (
          <PageImage
            blocks={props.blocks}
            blocksAlign={props.blocksAlign}
            blocksVariant={props.blocksVariant}
            src={props.src}
            duration={props.duration}
            onNext={onNext}
            canPlay={!paused}
            disableMotion={disableMotion}
            enableActionIconButton={enableActionIconButton}
          />
        )}
        {type === 'video' && (
          <PageVideo
            blocks={props.blocks}
            blocksAlign={props.blocksAlign}
            blocksVariant={props.blocksVariant}
            disableMotion={disableMotion}
            src={props.src}
            noControls={props.noControls}
            fillScreen={props.fillScreen}
            onNext={onNext}
            canPlay={!paused}
            loop={props.loop}
            duration={props.duration}
            hiddenControls={hiddenControls}
            submitLabel={props.submitLabel}
            enableActionIconButton={enableActionIconButton}
          />
        )}
        {type === 'form' && (
          <PageForm
            id={props.id}
            beforeBlocks={props.beforeBlocks}
            fields={props.fields}
            onSubmit={handleFormSubmit}
            onSkip={handleFormSkip}
            onBack={onBack}
            onNext={onNext}
            onClose={props.onClose}
            enableSkip={props.enableSkip}
            submitLabel={props.submitLabel}
            submitLabelOnEmpty={props.submitLabelOnEmpty}
            skipLabel={props.skipLabel}
            enableClose={props.enableClose}
            setHiddenTitle={setHiddenTitle}
            disableMotion={disableMotion}
            disabled={loading}
          />
        )}
        {type === 'custom' && (
          <PageCustom
            {...props}
            id={props.id}
            onBack={onBack}
            onNext={onNext}
            onClose={props.onClose}
            canPlay={!paused}
            disableMotion={disableMotion}
          />
        )}
        {type === 'story-start' && (
          <PageStoryStart
            {...props}
            id={props.id}
            onNext={onNext}
            disableMotion={disableMotion}
          />
        )}
        {type === 'story-complete' && (
          <PageStoryComplete
            {...props}
            disableMotion={disableMotion}
          />
        )}
        {type === 'template-overview' && (
          <PageTemplateOverview
            {...props}
            id={props.id}
            disableMotion={disableMotion}
            onNext={onNext}
            gotoPage={props.gotoPage}
            onSubmit={props.onSubmit}
          />
        )}
        <ContentDrawer
          {...articleDialogProps}
          title={article.title}
          content={article.content}
          error={!!articleError}
        />
        <LoadingBackdrop open={articleLoading} invisible />
      </Stack>
    </>
  )
}
