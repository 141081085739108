import React, { Fragment, useLayoutEffect, useRef } from 'react'
import { Box, DialogContent, Stack } from '@mui/material'
import MarkdownContent from 'components/MarkdownContent'
import { EnhancedDialogTitle } from 'components/EnhancedDialog'
import EnhancedDrawer, {
  EnhancedDrawerProps,
} from 'components/EnhancedDrawer'
import { textBlockStyles } from 'components/blocks/TextBlock'

export type ContentDrawerProps = EnhancedDrawerProps & {
  title: string
  content: string
  error?: boolean
}

export default function ContentDrawer(props: ContentDrawerProps) {
  const { title, content, error, ...dialogProps } = props
  const dialogRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    // Scroll to top when content changes (e.g. visiting a nested article).
    dialogRef.current?.querySelector('.MuiPaper-root').scrollTo(0, 0)
  }, [content])

  return (
    <EnhancedDrawer
      {...dialogProps}
      slotProps={{
        root: { ref: dialogRef },
      }}
      disableFullHeight={!error}
      sx={{
        '& .MuiDrawer-paper': {
          '& > .MuiStack-root': {
            gap: 0,
          },
          '@media (min-height: 1067px)': {
            borderRadius: 2,
            my: 'auto',
          },
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          containerType: 'inline-size',
          height: 1,
          mb: 0,
          p: 0,
        },
        zIndex: ({ zIndex }) => zIndex.modal,
      }}
    >
      <EnhancedDialogTitle
        onClose={() => dialogProps.onClose({}, 'backdropClick')}
        sx={{
          bgcolor: 'background.paper',
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          borderBottomWidth: title ? 1 : 0,
          position: 'sticky',
          top: 0,
        }}
      >
        <Stack
          sx={{
            alignItems: 'center',
            flexDirection: 'row',
            gap: '.25em',
          }}
        >
          <MarkdownContent
            value={title}
            components={{ p: Fragment }}
          />
        </Stack>
      </EnhancedDialogTitle>
      <DialogContent
        sx={{
          overflowY: 'auto',
          pb: 14,
          ...(error
            ? {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '80cqh',
                justifyContent: 'center',
                textAlign: 'center',
              }
            : null),
        }}
      >
        {error && (
          <Box component="img" src="/assets/page-not-found-1.svg" />
        )}
        <MarkdownContent
          value={content}
          sx={{
            ...textBlockStyles,
            '& a': {
              '&:hover': {
                color: 'primary.main',
              },
              color: 'primary.light',
            },
            '& img': {
              bgcolor: 'grey.100',
              display: 'block',
              height: 'auto',
              mx: -3,
              my: 3,
              width: '100cqw',
            },
            '& img:first-child, & p:first-child img': {
              mt: -3,
            },
            display: 'block',
            flexDirection: 'column',
            mt: title ? 3 : 0,
            typography: 'body2',
          }}
        />
      </DialogContent>
    </EnhancedDrawer>
  )
}
