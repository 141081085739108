import React from 'react'
import {
  Backdrop,
  BackdropProps,
  CircularProgress,
  alpha,
} from '@mui/material'

export default function LoadingBackdrop(props: BackdropProps) {
  return (
    <Backdrop
      {...props}
      sx={{
        bgcolor: ({ palette }) =>
          !props.invisible &&
          alpha(
            palette.mode === 'dark'
              ? palette.common.black
              : palette.common.white,
            0.33,
          ),
        // Fix Safari desktop issue where hidden backdrops
        // prevent scrolling for elements behind it.
        pointerEvents: props.open ? null : 'none',
        zIndex: 1301,
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
