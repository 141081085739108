import React, { WheelEvent, useEffect, useState } from 'react'
import {
  Divider,
  IconButton,
  IconButtonProps,
  InputBase,
  Stack,
  Typography,
} from '@mui/material'
import {
  Field,
  FieldProps,
  FormValues,
} from 'components/StoryPlayer/pages/PageForm'
import FormControl from '@mui/material/FormControl'
import StoryFieldLabel from 'components/StoryFieldLabel'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import { Controller, UseFormReturn } from 'react-hook-form'
import { omit } from 'lodash'
import { isValidNumber, parseNumberString } from 'utils/FormUtil'

export interface StepperField extends Field {
  type: 'stepper'
  helperText?: string
  min?: number
  max?: number
  autoFocus?: boolean
  defaultValue?: number
}

export interface StoryStepperFieldProps
  extends StepperField,
    FieldProps {
  disabled?: boolean
  formContext: UseFormReturn<FormValues>
}

export default function StoryStepperField(
  props: StoryStepperFieldProps,
) {
  const {
    fieldState,
    formContext,
    label,
    required,
    disableRequiredAsterisk,
    inputRef,
    name,
    defaultValue,
    onChange,
    max,
    min,
  } = props
  const { control, clearErrors } = formContext
  const [value, setValue] = useState(
    parseInt(String(props.value)) || 0,
  )
  const { error } = fieldState
  const helperText = error?.message || props.helperText

  // Sync local state value with props.
  useEffect(() => {
    setValue(parseInt(String(props.value)) || 0)
  }, [props.value])

  return (
    <FormControl
      className={props.className}
      fullWidth
      error={!!error}
      sx={{
        '&.last-item > .MuiDivider-root': {
          display: 'none',
        },
        alignItems: 'center',
        flexDirection: 'row',
        gap: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack>
        <StoryFieldLabel
          label={label}
          required={required && !disableRequiredAsterisk}
          sx={{ mb: 0 }}
        />
        <Typography variant="body2" color="textSecondary">
          {helperText}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row' }}>
        <StepperButton
          variant="subtract"
          disabled={value <= min}
          onClick={() => {
            const valueDecremented = Math.max(value - 1 || 0, min)
            setValue(valueDecremented)
            onChange(valueDecremented)
          }}
        />
        <Controller
          name={name}
          control={control}
          defaultValue={
            isValidNumber(defaultValue) ? defaultValue : ''
          }
          rules={{ required }}
          render={({ field }) => (
            <InputBase
              {...omit(field, 'ref')}
              inputRef={inputRef}
              color="secondary"
              inputProps={{
                inputMode: 'numeric',
                onWheel: (e: WheelEvent<HTMLInputElement>) =>
                  e.currentTarget.blur(),
              }}
              readOnly
              type="text"
              autoFocus={props.autoFocus}
              onBlur={props.onBlur}
              onChange={(e) => {
                clearErrors()
                const value = parseNumberString(e.target.value)
                const valueAsNumber = parseInt(value)
                const isValid = !!value && isFinite(Number(value))

                setValue(valueAsNumber)
                onChange(isValid ? valueAsNumber : '')
              }}
              value={isValidNumber(value) ? value : ''}
              fullWidth
              disabled={props.disabled}
              required={required}
              error={!!error}
              sx={{
                '& > .MuiInputBase-input': {
                  fontSize: '1.5rem',
                  lineHeight: 1,
                  p: 0,
                  textAlign: 'center',
                },
                maxWidth: '3.5em',
              }}
            />
          )}
        />
        <StepperButton
          variant="add"
          disabled={value >= max}
          onClick={() => {
            const valueIncremented = Math.min(value + 1 || 0, max)
            setValue(valueIncremented)
            onChange(valueIncremented)
          }}
        />
      </Stack>
      <Divider
        sx={{ bottom: 0, mb: -2.5, position: 'absolute', width: 1 }}
      />
    </FormControl>
  )
}

type StepperButtonProps = IconButtonProps & {
  variant: 'add' | 'subtract'
}

function StepperButton(props: StepperButtonProps) {
  const { variant, ...iconButtonProps } = props
  const Icon = variant === 'add' ? AddRoundedIcon : RemoveRoundedIcon

  return (
    <IconButton
      {...iconButtonProps}
      sx={{
        border: '1px solid',
        height: 40,
        opacity: iconButtonProps.disabled ? 0.5 : 1,
        transition: 'opacity 0.2s',
        width: 40,
      }}
    >
      <Icon />
    </IconButton>
  )
}
