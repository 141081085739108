import { StoryData } from 'components/StoryPlayer'
import mciStoryIntro from 'components/StoryPlayer/Modules/Chapters/chapters/mci-story-intro.story.json'
import mciStoryOutro from 'components/StoryPlayer/Modules/Chapters/chapters/mci-story-outro.story.json'
import mciStoryIntroEs from 'components/StoryPlayer/Modules/Chapters/chapters/mci-story-intro-es.story.json'
import mciStoryOutroEs from 'components/StoryPlayer/Modules/Chapters/chapters/mci-story-outro-es.story.json'
import uicEdCancerEducationModuleChapter from 'components/StoryPlayer/Modules/Chapters/chapters/uic-ed-cancer-education.story.json'
import uicEdCancerEducationModuleChapterEs from 'components/StoryPlayer/Modules/Chapters/chapters/uic-ed-cancer-education-es.story.json'
import resultsOverviewCourseApc from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-apc.story.json'
import resultsOverviewCourseAtm from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-atm.story.json'
import resultsOverviewCourseAxin2 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-axin2.story.json'
import resultsOverviewCourseBap1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-bap1.story.json'
import resultsOverviewCourseBard1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-bard1.story.json'
import resultsOverviewCourseBmpr1a from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-bmpr1a.story.json'
import resultsOverviewCourseBrca1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-brca1.story.json'
import resultsOverviewCourseBrca2 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-brca2.story.json'
import resultsOverviewCourseBrip1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-brip1.story.json'
import resultsOverviewCourseCdh1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-cdh1.story.json'
import resultsOverviewCourseCdk4 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-cdk4.story.json'
import resultsOverviewCourseCdkn2a from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-cdkn2a.story.json'
import resultsOverviewCourseCftr from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-cftr.story.json'
import resultsOverviewCourseChek2 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-chek2.story.json'
import resultsOverviewCourseEpcam from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-epcam.story.json'
import resultsOverviewCourseGrem1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-grem1.story.json'
import resultsOverviewCourseHoxb13 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-hoxb13.story.json'
import resultsOverviewCourseMen1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-men1.story.json'
import resultsOverviewCourseMitf from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-mitf.story.json'
import resultsOverviewCourseMlh1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-mlh1.story.json'
import resultsOverviewCourseMsh2 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-msh2.story.json'
import resultsOverviewCourseMsh3 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-msh3.story.json'
import resultsOverviewCourseMsh6 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-msh6.story.json'
import resultsOverviewCourseMutyh from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-mutyh.story.json'
import resultsOverviewCourseNthl1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-nthl1.story.json'
import resultsOverviewCoursePalb2 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-palb2.story.json'
import resultsOverviewCoursePms2 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-pms2.story.json'
import resultsOverviewCoursePold1 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-pold1.story.json'
import resultsOverviewCoursePole from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-pole.story.json'
import resultsOverviewCoursePten from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-pten.story.json'
import resultsOverviewCourseRad51c from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-rad51c.story.json'
import resultsOverviewCourseRad51d from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-rad51d.story.json'
import resultsOverviewCourseRet from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-ret.story.json'
import resultsOverviewCourseRnf43 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-rnf43.story.json'
import resultsOverviewCourseSdha from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-sdha.story.json'
import resultsOverviewCourseSdhb from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-sdhb.story.json'
import resultsOverviewCourseSdhc from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-sdhc.story.json'
import resultsOverviewCourseSdhd from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-sdhd.story.json'
import resultsOverviewCourseSmad4 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-smad4.story.json'
import resultsOverviewCourseStk11 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-stk11.story.json'
import resultsOverviewCourseTp53 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-tp53.story.json'
import resultsOverviewCourseTsc from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-tsc.story.json'
import resultsOverviewCourseVhl from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-vhl.story.json'
import resultsOverviewCourseMybpc3 from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-mybpc3.story.json'
import resultsOverviewCourseTtn from 'components/StoryPlayer/Modules/Chapters/chapters/results-overview-course-ttn.story.json'
import { getStoryFromChapters } from 'components/StoryPlayer/Modules/Chapters/chaptersUtil'
import { BaseModuleConfig } from 'components/StoryPlayer/Modules'
import { InterpolationData } from 'hooks/useInterpolate'
import { interpolateData } from 'utils/ObjectUtil'

export const ChaptersModuleChapters = [
  mciStoryIntro,
  mciStoryOutro,
  mciStoryIntroEs,
  mciStoryOutroEs,
  uicEdCancerEducationModuleChapter,
  uicEdCancerEducationModuleChapterEs,
  resultsOverviewCourseApc,
  resultsOverviewCourseAtm,
  resultsOverviewCourseAxin2,
  resultsOverviewCourseBap1,
  resultsOverviewCourseBard1,
  resultsOverviewCourseBmpr1a,
  resultsOverviewCourseBrca1,
  resultsOverviewCourseBrca2,
  resultsOverviewCourseBrip1,
  resultsOverviewCourseCdh1,
  resultsOverviewCourseCdk4,
  resultsOverviewCourseCdkn2a,
  resultsOverviewCourseCftr,
  resultsOverviewCourseChek2,
  resultsOverviewCourseEpcam,
  resultsOverviewCourseGrem1,
  resultsOverviewCourseHoxb13,
  resultsOverviewCourseMen1,
  resultsOverviewCourseMitf,
  resultsOverviewCourseMlh1,
  resultsOverviewCourseMsh2,
  resultsOverviewCourseMsh3,
  resultsOverviewCourseMsh6,
  resultsOverviewCourseMutyh,
  resultsOverviewCourseNthl1,
  resultsOverviewCoursePalb2,
  resultsOverviewCoursePms2,
  resultsOverviewCoursePold1,
  resultsOverviewCoursePole,
  resultsOverviewCoursePten,
  resultsOverviewCourseRad51c,
  resultsOverviewCourseRad51d,
  resultsOverviewCourseRet,
  resultsOverviewCourseRnf43,
  resultsOverviewCourseSdha,
  resultsOverviewCourseSdhb,
  resultsOverviewCourseSdhc,
  resultsOverviewCourseSdhd,
  resultsOverviewCourseSmad4,
  resultsOverviewCourseStk11,
  resultsOverviewCourseTp53,
  resultsOverviewCourseTsc,
  resultsOverviewCourseVhl,
  resultsOverviewCourseMybpc3,
  resultsOverviewCourseTtn,
] as StoryData[]

export type ChapterModuleConfig<
  T extends string,
  K extends string,
> = {
  type: T
  chapters: {
    storyId: K
    options?: Record<string, unknown>
  }[]
}

export type ChaptersConfig = BaseModuleConfig &
  ChapterModuleConfig<typeof ChaptersModule.type, string>

export class ChaptersModule {
  static type = 'Chapters' as const

  static getStory(
    config: ChaptersConfig,
    interpolationData: InterpolationData,
  ) {
    config.chapters = config.chapters.map((chapter) => {
      chapter.storyId = interpolateData(
        chapter.storyId,
        interpolationData,
      )
      return chapter
    })
    const { chapters = [] } = config
    return getStoryFromChapters(chapters, ChaptersModuleChapters)
  }
}
